import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { ArticleListPage } from '../../components/Blog';
import { Article } from '../../objects/Blog';

const BlogPage = ({
  data,
  pageContext,
}: {
  data: {
    allContentfulArticle: { edges: [{ node: Article }] };
  };
  pageContext: {
    blogRootPath: string;
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
    nodeLocale: string;
    canonicalPath: string;
  };
}) => {
  const { t } = useTranslation('blog');
  const { blogRootPath, numPages, currentPage, nodeLocale, canonicalPath } =
    pageContext;

  const category = {
    id: 'blogPage.id',
    name: t('title'),
    metaTitle: `CloudFit - ${t('metaTagTitle')}`,
    slug: 'blog',
    localeCode: nodeLocale,
    description: { childMarkdownRemark: { rawMarkdownBody: t('description') } },
  };

  return ArticleListPage({
    articlesData: data.allContentfulArticle.edges,
    blogRootPath,
    category,
    numPages,
    currentPage,
    canonicalPath,
  });
};

export default BlogPage;

export const pageQuery = graphql`
  query blogArticles($nodeLocale: String!, $limit: Int!, $skip: Int!) {
    allContentfulArticle(
      filter: { node_locale: { eq: $nodeLocale } }
      limit: $limit
      skip: $skip
      sort: { fields: [publishDate, title], order: [DESC, ASC] }
    ) {
      edges {
        node {
          id
          title
          node_locale
          slug
          publishDate(formatString: "MMMM D, YYYY")
          description
          heroImage {
            title
            description
            image: gatsbyImageData(width: 1920)
            mimeType
            url
          }
          heroImageLink
          body {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          author {
            name
            photo {
              title
              description
              image: gatsbyImageData(width: 100)
            }
          }
          tags
          readingTimeMinutes
          category {
            id
            slug
            name
          }
        }
      }
    }
  }
`;
